<template>
  <div class="hello">
    <div class="btn">
      <el-button type="danger" @click="getMo" size="small">下载模板</el-button>
      <el-upload
        class="upload-demo"
        action
        :on-change="handleChange"
        :on-exceed="handleExceed"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :file-list="fileListUpload"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button size="small" type="primary">选择文件</el-button>

        <!-- <div slot="tip" class="el-upload__tip">只 能 上 传 xlsx / xls 文 件</div> -->
      </el-upload>
      <!-- 导出 -->
      <el-button size="small" @click='saveAll' type="primary">保存</el-button>
      <!-- <el-button @click="outExe" size="small">导出</el-button> -->
    </div>
    <div>
        <template>

            年龄:<input type="radio" name="love" v-model="radio" value="年龄">
            属相:<input type="radio" name="love" v-model="radio" value="属相">
            主星:<input type="radio" name="love" v-model="radio" value="主星">
          </template>
    </div>

    <!-- 这里做一个表格 -->
    <el-table
      :data="dataLists[listQuery.page - 1]"
      style="width: 100%; margin-top: 50px"
      border
    >

      <el-table-column prop="age" label="年龄" min-width="100" align="center">
      </el-table-column>
       <el-table-column prop="sex" label="性别" min-width="100" align="center">
      </el-table-column>

      <el-table-column
        prop="pair_ages"
        label="配对年龄"
        min-width="200"
        align="center"
      >
      </el-table-column>
       <el-table-column
        prop="score"
        label="打分"
        min-width="20"
        align="center"
      >
      </el-table-column>
    </el-table>

    <el-button @click="update" class="update">刷新</el-button>

    <pagination
      v-show="total > 0"
      :page-sizes="pagesizes"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="list"
    />
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from './Pagination.vue';

export default {
  data() {
    return {
      // total:0,
      pagesizes: [5, 10, 20],
      radio:"年龄",
      listQuery: {
        page: 1,
        limit: 10
      },
      imageUrl: '',
      msg: 'hello,Element',
      limitUpload: 2,
      fileTemp: '',
      file: '',
      fileListUpload: [],
      excelData: [],
      dataList: [{  age: '年龄', sex: '性别', pair_ages: '配对年龄',score:'打分'}],
      dataLists: [],
      dataListss: []
    };
  },
  created() {
    this.list();

    // console.log(this.dataListss, "数据", this.listQuery.page);
  },
  components: {
    Pagination
  },
  methods: {
    update() {
      console.log(this.dataList);
      this.$forceUpdate();
    },
    list() {
      console.log(this.listQuery);
      let l = this.dataList.length;
      let p = this.listQuery.page;
      let m = this.listQuery.limit;
      let d = [...this.dataList];
      let s;
      if (l > m) {
        s = parseInt(l / m);
        if (l % m !== 0) {
          s += 1;
        }
      } else {
        s = 1;
      }

      for (let i = 0; i < s; i++) {
        this.dataLists[i] = d.splice(0, m);
      }
      console.log(this.dataLists, '这里是处理的数据', s, d);
    },
    handleChange(file, fileList) {
      console.log(file, fileList, '1111');

      this.fileTemp = file.raw;
      if (this.fileTemp) {
        // console.log(this.fileTemp.type)
        if (
          this.fileTemp.type ==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          this.fileTemp.type == 'application/vnd.ms-excel'
        ) {
          let s = this.importfxx(this.fileTemp);
          console.log(s, '打印一下');
        } else {
          this.$message({
            type: 'warning',
            message: '附件格式错误，请删除后重新上传!'
          });
        }
      }
    },

    importfxx(obj) {
      console.log(obj);
      let _this = this;
      // 通过DOM取文件数据
      this.file = obj;
      var rABS = false; // 是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();
      // if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = '';
        var rABS = false; // 是否将文件读取为二进制字符串
        var pt = this;
        var wb; // 读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function (e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          var XLSX = require('xlsx')
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              // 手动转化
              type: 'base64'
            });
          } else {
            wb = XLSX.read(binary, {
              type: 'binary'
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); // outdata就是你想要的东西
          this.da = [...outdata];
          let arr = [];
          let _arr = {

            年龄: 'age',
            性别: 'sex',
            配对年龄: 'pair_ages',
            打分:'score'

          };
          this.da.map((v) => {
            let obj = {};
            for (let k in _arr) {
              obj[_arr[k]] = v[k];
            }
            arr.push(obj);
          });
          _this.dataList = arr;
          console.log(arr, '导入的数据', _this.dataList);
          _this.list();
          // 莫非这里只能去执行方法嘛
          return arr;
          // 为啥没返回出去呢,接收到
        };
        reader.readAsArrayBuffer(f);
      };

      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleRemove(file, fileList) {
      // console.log(file)
      this.fileTemp = null;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },

    // 创建模板
    getMo() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require('../excal/Export2Excel'); // 这里必须使用绝对路径，根据自己的命名和路径
        const tHeader = [ '年龄', '性别', '配对年龄','打分']; // 导出的表头名

        const list = [];

        // console.log(list, '原来的数据没有改变吧');

        export_json_to_excel(tHeader, list, `模板文件`); // 导出的表格名称，根据需要自己命名
        // tHeader为导出Excel表头名称，`xxxxxx`即为导出Excel名称
      });
    },

    // 导出
    outExe() {
      this.$confirm('此操作将导出excel文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.excelData = this.dataList; // 你要导出的数据list。
          this.export2Excel();
        })
        .catch(() => {});
    },

   async saveAll() {

       const arrChunk = (arr,size) =>{
            if(arr.length === 0){
                return [];
            }
            const tempArr = [];
            const chunkLen = Math.ceil(arr.length / size);
            let last = 0;
            for(let i = 0;i<chunkLen;i++){
                if(size * i >= arr.length){
                    last = arr.length;
                }else{
                    last = size * i + size;
                }
                tempArr.push(arr.slice(size * i,last));
            }
            return tempArr;
        }

        let postDataArr = arrChunk(this.dataLists,50)
        for(let i = 0; i< postDataArr.length; i++){
            const { data: res } =  await this.$http.post('marrAge/insertmany', { postData: postDataArr[i], delete: 0 ,marr_type:this.radio})
            if(res.meta.status !== 200) {
              console.log(res)
              return this.$message.error(res.meta.msg)
            }
            else
            {
              // 添加成功的提示

            this.$message.success('添加成功')
            }
        }
        this.dataLists = []



    },

    // 导出方法
    export2Excel() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require('../excal/Export2Excel'); // 这里必须使用绝对路径，根据自己的命名和路径
        const tHeader = [ '年龄', '性别', '配对年龄','打分']; // 导出的表头名

        const filterVal = ['age', 'sex', 'pair_ages','score'];
        // 导出的表头字段名

        const list = that.excelData;
        // that.excelData为传入的数据
        const data = that.formatJson(filterVal, list);
        console.log(list, '原来的数据没有改变吧');

        export_json_to_excel(tHeader, data, `测试导出excel`); // 导出的表格名称，根据需要自己命名
        // tHeader为导出Excel表头名称，`xxxxxx`即为导出Excel名称
      });
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    }
  },
  computed: {
    total() {
      return this.dataList.length;
    }
  }
}
/* eslint-disable */
</script>
<style scoped>
.btn {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.update {
  margin-top: 50px;
}
</style>
