<template>
    <div>
        <!-- <div class="heading">
        <h1>Vue幻灯片</h1>
        <h4>基于Vue.js构建的简单照片相册</h4>
        </div> -->
        <div class="container">
            <vue-gallery :photos="photos"></vue-gallery>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
 data(){
     return {
       photos: [
           require(`../assets/img/lordea-home-01-min.jpg`),
           require(`../assets/img/lordea-home-02-min.jpg`),
           require(`../assets/img/lordea-home-03-min.jpg`),
           require(`../assets/img/lordea-home-04-min.jpg`),
           require(`../assets/img/lordea-home-05-min.jpg`),
           require(`../assets/img/lordea-home-06-min.jpg`),
           require(`../assets/img/lordea-home-07-min.jpg`),
           require(`../assets/img/lordea-home-08-min.jpg`)
      ]
     }
 }

}
/* eslint-disable */
</script>
