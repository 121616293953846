<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/carousel' }">小程序配置</el-breadcrumb-item>
      <el-breadcrumb-item>添加轮播图</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-alert title="添加轮播图信息" type="info" center show-icon :closable="false"></el-alert>

      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="left">

        <!-- action：图片上传地址 -->
        <el-upload style="margin-top: 30px;margin-bottom: 20px;" accept=".jpeg, .jpg, .png, .mp4, .jpeg, .webp"
          multiple="false" :action="imgUploadURL" :on-preview="handlePreview" :on-remove="handleRemove" list-type="text"
          :headers="headersObj" :on-success="handleSuccess" :limit="1">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">选择您要展示的轮播图(jpeg jpg png gif)</div>
        </el-upload>
        <el-form-item label="名称" prop="name" style="width: 40%;">
          <el-input v-model="addForm.name" type="text"></el-input>
        </el-form-item>
        <el-form-item label="顺序" prop="sequence" style="width: 8%;">
          <el-input v-model="addForm.sequence" type="number"></el-input>
        </el-form-item>
        <el-form-item label="跳转链接" prop="url" style="width: 40%;">
          <el-input v-model="addForm.url" type="text"></el-input>
        </el-form-item>
        <el-form-item label="位置" prop="location">
          <el-select v-model="addForm.location" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <el-switch v-model="addForm.is_view" active-text="可见" inactive-text="不可见">
        </el-switch>

      </el-form>
      <el-button type="primary" @click="add()" style="margin-left: 5px;margin-top: 20px;">添加</el-button>
      <el-dialog title="预览图片" :visible.sync="previewImgVisible" width="50%">
        <img :src="previewPath" alt class="previewImg" />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  import _ from 'lodash'
  export default {
    data () {
      return {
        options: [{
          value: '顶部',
          label: '顶部',

        }, {
          value: '底部',
          label: '底部',

        },],
        // 添加表单对象
        addForm: {
          img: '',
          sequence: 1,
          is_view: true,
          url: ''
        },
        // 表单验证规则
        addFormRules: {

          sequence: [
            { required: true, message: '请输入展示顺序', trigger: 'blur' }
          ]

        },

        // 上传图片地址
        // imgUploadURL: 'http://localhost:3000/api/private/v1/upload',
        imgUploadURL: 'https://api.hyxz.cc/api/private/v1/upload',

        // 图片上传使用内部封装的axjx，需要为其指定token
        headersObj: {
          Authorization: window.sessionStorage.getItem('token')
        },
        // 图片预览地址
        previewPath: '',
        // 是否显示图片预览对话框
        previewImgVisible: false
      }
    },
    created () {

    },
    methods: {

      // 点击图片预览触发
      handlePreview (file) {
        this.previewPath = file.response.data.url
        this.previewImgVisible = true
      },
      // 移除图片触发
      handleRemove (file) {
        console.log(file)
        // 获取图片在服务器上的临时地址
        const uploadURL = file.response.data.tmp_path
        // 根据图片地址查找其在addForm的pics中的下标
        const i = this.addForm.pics.findIndex(item =>
          item.pic === uploadURL
        )
        // 删除对应下标项
        this.addForm.pics.splice(i, 1)
        console.log(this.addForm)
      },
      // 上传成功触发
      handleSuccess (response) {
        // 将返回的图片的临时地址保存到商品表单中
        this.addForm.img = response.data.tmp_path
        console.log(response)
        console.log(this.addForm)
      },
      // 点击添加商品
      add () {

        // 添加商品
        //  const { data: res } =  this.$http.post('carousel', this.addForm)
        this.$http.post('carousel', this.addForm).then(res => {
          if (res.data.meta.status !== 201) {
            return this.$message.error(res.data.meta.msg)
          }
          this.$message.success(res.data.meta.msg)
          console.log('跳转')
          // 跳转到商品列表页
          this.$router.push('/carousel')
        })




      }
    },
    // 计算属性
    computed: {


    }
  }
</script>

<style lang="less" scoped>
  .el-checkbox {
    margin: 0 10px 0 0 !important;
  }

  .previewImg {
    width: 100%;
  }

  .addBtn {
    margin-top: 15px;
  }
</style>