<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/carousel' }">小程序配置</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片面板 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @clear="getCarouselList">
            <el-button slot="append" icon="el-icon-search" @click="getCarouselList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="goAddPage">添加轮播图</el-button>
        </el-col>

      </el-row>
      <el-row style="margin-top: 20px;">
        <el-col style="display: flex;align-items: center;" v-if="syscarousel[0]">
          <div style="font-weight:900;font-size: 18px; margin-right: 20px;">是否展示顶部广告</div>
          <el-select v-model="syscarousel[0].is_view"
            @change="updateSysCarousel(syscarousel[0].id, syscarousel[0].location)" placeholder="请选择">
            <el-option v-for="item in optionsCarouselVis" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col style="display: flex;align-items: center;margin-top: 20px;" v-if="syscarousel[1]">
          <div style="font-weight:900;font-size: 18px;margin-right: 20px;">是否展示底部广告</div>
          <el-select v-model="syscarousel[1].is_view"
            @change="updateSysCarousel(syscarousel[1].id, syscarousel[1].location)" placeholder="请选择">
            <el-option v-for="item in optionsCarouselVis" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col style="display: flex;align-items: center;margin-top: 20px;">
          <div style="font-weight:900;font-size: 18px;margin-right: 20px;">合星座广告位</div>
          <img v-if="hexinweiz[0]" :src="hexinweiz[0].img" alt="图片"
            v-bind:style="{ maxWidth: '200px', maxHeight: '200px' }">
          <!-- <el-upload style="margin-top: 30px;margin-bottom: 20px;" :action="imgUploadURL" :on-remove="handleRemove"
            list-type="picture" :headers="headersObj" :on-success="handleSuccess" :limit="1">
            <div slot="tip" class="el-upload__tip" style="margin-left: 50px;">选择您要展示的广告图(jpeg jpg png gif)</div>
          </el-upload> -->
          <el-upload class="upload-demo" :action="imgUploadURL" :headers="headersObj" :on-success="handleSuccess"
            :limit="1" show-file-list:false style="margin-left: 100px;">
            <i class="el-icon-upload"></i>
            <em>点击上传</em>
            <div class="el-upload__tip" slot="tip">选择您要展示的广告图(jpeg jpg png gif)</div>
          </el-upload>
        </el-col>


        <el-col style="display: flex;align-items: center;margin-top: 20px;">
          <div style="font-weight:900;font-size: 18px;margin-right: 20px;">查看爱情指数广告位</div>
          <img v-if="aiqingzhishu[0]" :src="aiqingzhishu[0].img" alt="图片"
            v-bind:style="{ maxWidth: '200px', maxHeight: '200px' }">
          <!-- <el-upload style="margin-top: 30px;margin-bottom: 20px;" :action="imgUploadURL" :on-remove="handleRemove"
            list-type="picture" :headers="headersObj" :on-success="handleSuccess" :limit="1">
            <div slot="tip" class="el-upload__tip" style="margin-left: 50px;">选择您要展示的广告图(jpeg jpg png gif)</div>
          </el-upload> -->
          <el-upload class="upload-demo" :action="imgUploadURL" :headers="headersObj" :on-success="handleSuccess2"
            :limit="1" show-file-list:false style="margin-left: 100px;">
            <i class="el-icon-upload"></i>
            <em>点击上传</em>
            <div class="el-upload__tip" slot="tip">选择您要展示的广告图(jpeg jpg png gif)</div>
          </el-upload>
        </el-col>
      </el-row>



      <el-table :data="carouselist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="img" label="轮播图">
          <template slot-scope="scope">
            <el-button v-if="scope.row.type == 'mp4'" type="success" plain @click="playVideo(scope.row.img)" ref="btn"
              size="mini" style="align-items: center;">
              播 放
            </el-button>
            <img v-else :src="scope.row.img" alt="图片" v-bind:style="{ maxWidth: '200px', maxHeight: '200px' }">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="sequence" label="顺序"></el-table-column>
        <el-table-column prop="url" label="跳转链接"></el-table-column>

        <el-table-column prop="is_view" label="是否可见">
          <template slot-scope="scope">
            <i class="el-icon-success" v-if="scope.row.is_view"
              style="font-size: 18px;color: #20A53A;font-weight: bold;">可见</i>
            <i class="el-icon-error" v-else style="font-size: 18px;color: #DE4100;font-weight: bold;">不可见</i>
          </template>
        </el-table-column>
        <el-table-column prop="location" label="位置">
          <template slot-scope="scope">
            <i class="el-icon-top" v-if="scope.row.location === '顶部'" style="font-size: 18px;font-weight: bold;">顶部</i>
            <i class="el-icon-bottom" v-else style="font-size: 18px;font-weight: bold;">底部</i>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="创建时间">
          <template slot-scope="scope">{{ scope.row.add_time | dateFormat }}</template>
        </el-table-column>
        <el-table-column label="操作" width="130px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="editById(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagesize" :page-sizes="[3, 5, 10, 15]" :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="this.total" background></el-pagination>

    </el-card>

    <!-- 视频 -->
    <el-dialog title="视频预览" :visible.sync="dialogPlay" width="30%" @close="closeDialog">
      <video :src="videoUrl" controls autoplay class="video" ref="dialogVideo" width="100%"></video>
    </el-dialog>

    <!-- 修改商品 -->
    <el-dialog title="编辑轮播图" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <!-- 主体内容 -->
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
        <!-- <el-form-item label="图片" prop="img">
          <el-input v-model="editForm.img"></el-input>
        </el-form-item> -->
        <el-form-item label="顺序" prop="sequence">
          <el-input v-model="editForm.sequence" type="number"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="sequence">
          <el-input v-model="editForm.name" type="text"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="editForm.url" type="text"></el-input>
        </el-form-item>
        <el-form-item label="位置" prop="location">
          <el-select v-model="editForm.location" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="is_view">
          <el-switch v-model="editForm.is_view" :active-value="1" :inactive-value="0" active-text="可见"
            inactive-text="不可见">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="eidtCarousel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 视频播放
      dialogPlay: false,
      videoUrl: '',
      // 上传图片地址
      imgUploadURL: 'https://api.hyxz.cc/api/private/v1/upload',
      // imgUploadURL: 'http://localhost:3000/api/private/v1/upload',
      // 图片上传使用内部封装的axjx，需要为其指定token
      headersObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      addForm: {

      },
      // 查询参数对象
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 140
      },
      syscarousel: [
        {
          id: 1,
          is_view: '不展示',
          location: '顶部'
        }
      ],
      optionsCarouselVis: [{
        value: '展示',
        label: '展示',

      }, {
        value: '不展示',
        label: '不展示',

      },],
      options: [{
        value: '顶部',
        label: '顶部',

      }, {
        value: '底部',
        label: '底部',

      },],
      // 轮播图列表
      carouselist: [],
      hexinweiz: [],
      aiqingzhishu: [],
      // 总数据条数
      total: 0,
      // 是否显示编辑商品对话框
      editDialogVisible: false,
      editForm: {
        img: '',
        sequence: '',
        is_view: '',
        url: ''
      },
      editFormRules: {
        img: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        sequence: [
          { required: true, message: '请输入展示顺序号', trigger: 'blur' }
        ],
        is_view: [
          { required: true, message: '是否启用', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    this.getCarouselList()
    this.getSysCarouselList()
  },
  methods: {
    // 视频
    playVideo(url) {
      this.dialogPlay = true;
      this.videoUrl = url;
    },
    closeDialog() {
      this.videoUrl = ""; //清空数据 关闭视频播放
    },
    // 上传成功触发
    async handleSuccess(response) {
      // 将返回的图片的临时地址保存到商品表单中
      console.log(response.data.tmp_path)
      let editForm = {
        id: this.hexinweiz[0].id,
        img: response.data.tmp_path
      }
      const { data: res } = await this.$http.put(`carousel/${this.editForm.goods_id}`, editForm)
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.$message.success('修改成功')
      this.getCarouselList()

    },

    // 上传成功触发
    async handleSuccess2(response) {
      // 将返回的图片的临时地址保存到商品表单中
      console.log(response.data.tmp_path)
      let editForm = {
        id: this.aiqingzhishu[0].id,
        img: response.data.tmp_path
      }
      const { data: res } = await this.$http.put(`carousel/${this.editForm.goods_id}`, editForm)
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.$message.success('修改成功')
      this.getCarouselList()

    },

    // 移除图片触发
    handleRemove(file) {
      console.log(file)
      // 获取图片在服务器上的临时地址
      const uploadURL = file.response.data.tmp_path
      // 根据图片地址查找其在addForm的pics中的下标
      const i = this.addForm.pics.findIndex(item =>
        item.pic === uploadURL
      )
      // 删除对应下标项
      this.addForm.pics.splice(i, 1)
      console.log(this.addForm)
    },
    // 获取数据列表
    async getCarouselList() {

      const { data: res } = await this.$http.get('carousel', {
        params: this.queryInfo
      })
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      }

      this.carouselistResult = res.data.carousel.map(item => {
        // item.img = 'https://api.hyxz.cc/' + item.img
        item.img = 'http://image.heyi666.cn/' + item.img
        return { ...item };
      });
      this.carouselist = this.carouselistResult.filter(obj => obj.location !== '合星座广告位' && obj.location !== '查看爱情指数');

      this.hexinweiz = this.carouselistResult.filter(obj => obj.location == '合星座广告位')
      this.aiqingzhishu = this.carouselistResult.filter(obj => obj.location == '查看爱情指数')
      this.total = res.data.total
      console.log('合星座广告位', this.hexinweiz)
    },
    // 获取轮播图系统配置列表
    async getSysCarouselList() {

      const { data: res } = await this.$http.get('syscarousel', {
        params: this.queryInfo
      })
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.syscarousel = res.data.syscarousel
      console.log(res.data.syscarousel)
    },
    async updateSysCarousel(id, location) {
      console.log('位置', location)
      console.log('选择的值:', this.syscarousel[id - 1].is_view);
      let SysCarousel = {
        id: id,
        location: location,
        is_view: this.syscarousel[id - 1].is_view
      }

      const { data: res } = await this.$http.put(`syscarousel/${id}`, SysCarousel)
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.$message.success(res.meta.msg)
      console.log('修改成功')
    },
    // pagesize改变
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      // 重新请求商品数据
      this.getCarouselList()
    },
    // pagenum改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getCarouselList();
      const { data: res } = this.$http.get(`syscarousel/${id}`)
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.status)
      }

    },
    // 编辑商品
    async editById(id) {
      console.log(id)
      const { data: res } = await this.$http.get(`carousel/${id}`)
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.status)
      }
      this.editForm = res.data
      this.editDialogVisible = true
    },

    // 根据ID删除商品
    async removeById(id) {
      const confirmResult = await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`carousel/${id}`)
      if (res.meta.status !== 200) {
        this.$message.error(res.meta.msg)
      }
      this.$message.success(res.meta.msg)
      this.getCarouselList()
    },
    // 点击跳转到添加商品页面
    goAddPage() {
      this.$router.push('/carousel/add')
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    // 编辑
    async eidtCarousel() {
      const { data: res } = await this.$http.put(`carousel/${this.editForm.goods_id}`, this.editForm)
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.$message.success(res.meta.msg)
      this.getCarouselList()
      this.editDialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped></style>