<template>
  <div>
    <quill-editor v-model="cooperation.context" ref="myQuillEditor" :options="editorOption"
      @change="onEditorChange($event)" @ready="onEditorReady($event)">
    </quill-editor>
    <el-button @click="update">提交</el-button>
  </div>

</template>

<script>
  export default {
    data () {
      return {

        cooperation:
        {
          id: 0,
          context: ""
        },
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
          ['blockquote', 'code-block'], // 引用  代码块
          [{ header: 1 }, { header: 2 }], // 1、2 级标题
          [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
          [{ script: 'sub' }, { script: 'super' }], // 上标/下标
          [{ indent: '-1' }, { indent: '+1' }], // 缩进
          [{ direction: 'rtl' }], // 文本方向
          [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
          [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
          [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
          // [{ font: ['songti'] }], // 字体种类
          [{ align: [] }], // 对齐方式
          ['clean'], // 清除文本格式
          ['image', 'video'] // 链接、图片、视频
        ],
        placeholder: '请输入正文'
      }

    },
    created () {
      this.getData()
    },
    methods: {
      async getData () {
        const { data: res } = await this.$http.get('seeting')
        if (res.meta.status !== 200) {
          return this.$message.error(res.meta.msg)
        }
        this.$message.success(res.meta.msg)
        this.cooperation = res.data.seeting[0]
      },

      async update () {
        let form = this.cooperation

        const { data: res } = await this.$http.put(`seeting/${form.id}`, form)
        if (res.meta.status !== 200) {
          return this.$message.success('修改成功')
        }
        this.$message.success(res.meta.msg)
      }
    }
  }
</script>

<style lang="less" scoped>
</style>