<template>
  <div class="hello">
    <h1>本命</h1>
    <div class="block">
      <span class="demonstration">输入生日 </span>
      <el-date-picker v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
        placeholder="Select date and time">
      </el-date-picker>
      <el-button @click="getPanData">生成命盘</el-button>
    </div>
    <div>
      {{ panData }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'liuYearPan',
  data() {
    return {
      panData: {},
      queryInfo: {
        birthday: '1976-11-14-10',
        sex: '男'
      },
      pickerOptions: {
        shortcuts: [{
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: 'Yesterday',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: 'A week ago',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      value1: '1977-01-06 22:00:00'
    }
  },
  created() {
    // this.getPanData()
  },
  methods: {
    // 查询星名列表数据
    async getPanData() {
      console.log(this.value1)
      let birthDate = new Date(this.value1)
      console.log(birthDate)
      //      queryInfo: {
      //     birthday: '1976-11-14-10',
      //     sex: '男'
      //   },
      let birDayString = birthDate.getFullYear() + '-' + (birthDate.getMonth() + 1) + '-' + birthDate.getDate() + '-' + birthDate.getHours()
      console.log(birDayString)
      this.queryInfo = {
        birthday: birDayString,
        sex: '男'
      }
      const { data: res } = await this.$http.get('panData', { params: this.queryInfo })
      if (res.meta.status !== 200) {
        return this.$message.error('获取列表失败！')
      }
      this.panData = res.data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
