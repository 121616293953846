<template>
  <div class="container">
        <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item :title="item.dirName" :name='key'  v-for="(item,key) in videList" :key="key">
          <div class="img-list">
           <div class='demo' v-for="(file,fileItem) in item.files" :key="fileItem">
                <video-player class="video-player vjs-custom-skin"
                            ref="videoPlayer"
                            :playsinline="true"
                            :options="getVideoOptions(file,item.dirName)">
                    </video-player>
            </div>
       </div>
        </el-collapse-item>
        </el-collapse>
  </div>
</template>

<script>
/* eslint-disable */
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  data() {
    return {
        videList:[],
        activeNames: [],
        imgBaseUrl:'https://api.hyxz.cc/videos/',
    };
  },
  components: {
    videoPlayer
  },
  created() {
      this.getVideoList()
  },
  methods: {
     handleChange(val) {
        console.log(val);
      },
       // 查询视频列表数据
    async getVideoList () {
      const { data: res } = await this.$http.get('video', {})
      if (res.meta.status !== 200) {
        return this.$message.error('获取视频列表失败！')
      }
      console.log(res.data)
      this.videList = res.data
    //   this.videList = res.data.users
    //   this.total = res.data.total
    },
    getVideoOptions(fileName,dirName){
        console.log(this.imgBaseUrl+dirName+"/"+fileName)
        var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
        let playerOptions =  {
                    //播放速度
                    playbackRates: [0.5, 1.0, 1.5, 2.0],
                    //如果true,浏览器准备好时开始回放。
                    autoplay: false,
                    // 默认情况下将会消除任何音频。
                    muted: false,
                    // 导致视频一结束就重新开始。
                    loop: false,
                    // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    preload: 'auto',
                    language: 'zh-CN',
                     // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    aspectRatio: '16:9',
                     // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    fluid: true,
                    sources: [{
                        //类型
                        type: "video/"+fileExtension,
                        //url地址
                        src: this.imgBaseUrl+dirName+"/"+fileName
                    }],
                    //你的封面地址
                    poster: '',
                     //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    notSupportedMessage: '此视频暂无法播放，请稍后再试',
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        //全屏按钮
                        fullscreenToggle: true
                    }
                }
         console.log(playerOptions)
          return playerOptions
        // type："video/webm"   // 可以播放，用ogg也可打开
        // type："video/ogg"    // 可以播放，用webm也可打开
        // type："video/3gp"    // 可以播放
        // type："video/mp4"    // 可以播放
        // type："video/avi"    // 打不开
        // type："video/flv"    // 打不开
        // type："video/mkv"    // 打不开
        // type："video/mov"    // 打不开
        // type："video/mpg"    // 打不开
        // type："video/swf"    // 打不开
        // type："video/ts"     // 打不开
        // type："video/wmv"    // 打不开
        // type："video/vob"    // 没转化
        // type："video/mxf"    // 转化出错
        // type: "video/rm"     // 转化出错复制代码
    }
  },
}
/* eslint-disable */
</script>
<style scoped>
.demo{
    display: inline-block;
    width: 350px;
    height: 260px;
    text-align: center;
    line-height: 100px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    margin-right: 4px;
  }
  .container{
    width:80%
  }

</style>
