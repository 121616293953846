<template>
    <div class="container">
        <h3>Api接口说明</h3>
        登录参照本系统登录，获取token,以下接口都需带token,其他接口可参照平台请求
        <el-divider></el-divider>

        <!-- <div class="api-div">
            <H5>喜用神八字查询</H5>
            地址:https://api.hyxz.cc//api/private/v1/bazi
            <div>
                method:GET
            </div>
            参数:
            <div>
                <span> ming:</span> <el-input style="width:200px" size="small" v-model="baziQeruy.ming"> </el-input>名
            </div>
            <div>
                <span> xing:</span> <el-input style="width:200px" size="small" v-model="baziQeruy.xing"> </el-input>姓

            </div>
            <div>
                <span> sex:</span> <el-input style="width:200px" size="small" v-model="baziQeruy.sex"> </el-input>性别 1男士，2女士

            </div>
            <div>
                <span> address: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.address"></el-input> 出生地 选填
            </div>
            <div>
                <span> year: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.year"> </el-input>年

            </div>
            <div>
                <span> yearType: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.yearType"> </el-input>1阳历，2农历

            </div>
            <div>
                <span> month: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.month"> </el-input>月

            </div>
            <div>
                <span> day: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.day"> </el-input>生辰日期，如09
            </div>
            <div>
                <span> hour: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.hour"> </el-input>生辰小时数，如08
            </div>
            <div>
                <span> minute: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.minute"> </el-input>分钟

            </div>

            <div>
                <span> lat: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.lat"> </el-input>经度
            </div>
            <div>
                <span> lng: </span>
                <el-input style="width:200px" size="small" v-model="baziQeruy.lng"> </el-input>纬度
            </div>



            <div>
                <el-button @click="testApi('bazi', 'GET', baziQeruy)" type="primary" size='small'>测试</el-button>
                <div>
                    结果:
                    {{ result }}
                </div>
            </div>
        </div> -->
        <div>
            <H5>查询星资料</H5>
            地址:https://api.hyxz.cc//api/private/v1/xingSerch
            <div>
                method:GET
            </div>
            参数:
            <div>
                "panname": '本命'
            </div>
            <div>
                "gongset": '命宫'
            </div>
            <div>
                "xingname": '飞廉'
            </div>
            <div>
                <el-button @click="testApi('xingSerch', 'GET', { panname: '本命', gongset: '命宫', xingname: '飞廉' })"
                    type="primary" size='small'>测试</el-button>
                <div>
                    结果:
                    {{ result }}
                </div>
            </div>
        </div>
        <div>
            <H5>支付接口</H5>
            地址:https://api.hyxz.cc/api/private/v1/pay/pay
            <div>
                method:POST
            </div>
            <div>
                参数: <br />
                params:{<br />
                trade_no:"" , //单号<br />
                goodName:"" , //商品名称<br />
                total_fee: 0,//金额 分<br />
                openid:"" //微信openId<br />
                }
            </div>
            <div>
                <el-divider></el-divider>

                <el-button @click="testApi2" type="primary" size='small'>支付测试</el-button>
                <div>
                    结果:
                    {{}}
                </div>
            </div>
        </div>
        <el-divider></el-divider>
        <div>
            <H5>合婚</H5>
            地址:https://api.hyxz.cc/api/private/v1/marrcomput
            <div>
                method:GET
            </div>
            <div>
                参数: <br />
                params:{<br />
                birthday:
                <el-date-picker v-model="person.birthday" type="datetime" placeholder="生日">
                </el-date-picker> //生日(阳历) <br />
                sex:
                <el-select v-model="person.sex" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> //性别
                <br> //城市 ,不传则搜所有
                city:
                <br>
                times: //请求次数
                <el-input v-model="person.city"></el-input>
                }
            </div>
            <div>
                <el-divider></el-divider>

                <el-button @click="marrBaseDataXing" type="primary" size='small'>合婚数据测试</el-button>
                <div>
                    结果:
                    {{ marrResult }}
                </div>
            </div>
        </div>
        <el-divider></el-divider>
        <div>
            <H5>俩人合婚测算</H5>
            地址:https://api.hyxz.cc/api/private/v1/marrcomput/twoMarrPair
            <div>
                method:GET
            </div>
            <div>
                参数: <br />
                params:{<br />
                birthdayOne:
                <el-date-picker v-model="person.birthdayOne" type="datetime" placeholder="A生日">
                </el-date-picker> //生日(阳历) <br />
                birthdayTwo:
                <el-date-picker v-model="person.birthdayTwo" type="datetime" placeholder="B生日">
                </el-date-picker> //生日(阳历) <br />
                oneSex:
                <el-select v-model="person.oneSex" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> //性别
                <br>
                twoSex:
                <el-select v-model="person.twoSex" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> //性别
                <br>
                }
            </div>
            <div>
                <el-divider></el-divider>

                <el-button @click="twoPersonPair" type="primary" size='small'>两人合婚数据测试</el-button>
                <div>
                    结果:
                    {{ twoPairResult }}
                </div>
            </div>
        </div>
        <div>
            <H5>婚姻观</H5>
            地址:https://api.hyxz.cc//api/private/v1/marrTopic
            <div>
                method:GET
            </div>
            参数:
            <div>
                "birthDate": <el-date-picker v-model="marrTopicDate" type="datetime" placeholder="生日">
                </el-date-picker> <br />
                <!-- // '1992-7-23-22' -->
            </div>
            <div>
                "sex": '男'
            </div>
            <div>
                <el-button @click="testApi('marrTopic', 'GET', { birthDate: marrTopicDate, sex: '男' })" type="primary"
                    size='small'>测试</el-button>
                <div>
                    结果:
                    {{ result }}
                </div>
            </div>
        </div>
        <div>
            <H5>五行选车</H5>
            地址:https://api.hyxz.cc//api/private/v1/wuXingSelectCar
            <div>
                method:GET
            </div>
            参数:
            <div>
                "birthDate": <el-date-picker v-model="wuXingCarDate" type="datetime" placeholder="生日">
                </el-date-picker> <br />
                <!-- // '1992-7-23-22' -->
            </div>
            <div>
                "sex": '男'
            </div>
            <div>
                <el-button @click="testApi('wuXingSelectCar', 'GET', { birthDate: wuXingCarDate, sex: '男' })" type="primary"
                    size='small'>测试</el-button>
                <div>
                    结果:
                    {{ wuXingResult }}
                </div>
            </div>
        </div>
        <el-button @click="purchase">立即购买</el-button>
        <p>
            let form={
            <br />order_id: 11,
            <br /> goods_id: 5, //需要
            <br /> goods_price: 221, //需要
            <br /> goods_number: 2, //需要
            <br /> create_date: '2022-11-30 17:24:50',, //需要
            <br /> shop_id: 1, //需要 ，购买链接对应的商户用户id 取表 ql_share_goods->userid 字段
            <br /> remark: 'remark', //需要
            <br /> field02: 'field02',
            <br /> field01: 'field01',
            <br /> status: '已下单', //需要
            }
            <br /> const { data: res } = await this.$http.post('qlgoodsSales', form)
        </p>
        <div>
            <el-button @click="getGoodsSalesList">我的商品订单查询</el-button>
            <p>
                const { data: res } = await this.$http.get('qlgoodsSales', { params:{

                <br /> creater: 500,
                <br /> status: '已下单',
                },
            </p>
        </div>
        <div>
            <el-button @click="createSharePage">生成分享页</el-button>
            <p>


                <br /> let form={
                <br /> userid: 500,
                <br /> goodids: JSON.stringify([{
                <br /> goodId:6,
                <br />
                purcharseLink:"https://pages.tmall.com/wow/an/cs/act/wupr?spm=a3204.7084713.1997320521.4.hl7dsm&wh_biz=tm&wh_pid=act%2F173d63dca19&disableNav=YES"
                <br /> },
                <br /> {
                <br /> goodId:5,
                <br />
                purcharseLink:"https://pages.tmall.com/wow/an/cs/act/wupr?spm=a3204.7084713.1997320521.4.hl7dsm&wh_biz=tm&wh_pid=act%2F173d63dca19&disableNav=YES"
                <br /> }]),
                <br /> createdate: '2022-11-30 17:24:50',
                <br /> status: "正常",
                <br /> field01:'field01',
                <br /> field02: 'field02',
                <br /> field03: 'field03',
                }
                <br /> const { data: res } = await this.$http.post('qlgoodsShare', form)
                <br /> if(res.meta.status !== 201) {
                <br /> return this.$message.error(res.meta.msg)
                <br /> }
                <br /> this.$message.success(res.meta.msg)
                },
            </p>
        </div>
        <div>
            <el-button @click="getSharePageList">获取我的分享页列表</el-button>
            <p> const { data: res } = await this.$http.get('qlgoodsShare', { params:{
                creater: 500,
                status: '正常',
                },
                })
                console.log('res.data:',res.data) </p>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    data() {
        return {
            baziQeruy: {
                address: '广东省 广州市 天河区',
                day: '09',
                hour: '08',
                lat: '23.126',
                lng: '113.244',
                ming: '天飞',
                minute: '01',
                month: '02',
                sex: 1,
                xing: '王',
                year: '1988',
                yearType: 1
            },
            marrResult: {},
            marrTopicDate: "",
            wuXingCarDate: '',
            twoPairResult: {},
            result: null,
            wuXingResult: '',
            person: {
                birthday: new Date('1977-10-01 10:00:00.000000'),
                sex: "",
                city: "",
                birthdayOne: new Date('1977-10-01 10:00:00.000000'),
                birthdayTwo: new Date('1977-10-01 10:00:00.000000'),
                oneSex: '男',
                twoSex: '女'
            },
            options: [{
                value: '男',
                label: '男'
            }, {
                value: '女',
                label: '女'
            }]

        };
    },
    created() {
    },
    methods: {

        async getSharePageList() {

            const { data: res } = await this.$http.get('qlgoodsShare', {
                params: {
                    // query: {key:"",marr_type:'主星'},
                    creater: 500,
                    status: '正常',
                },
            })
            console.log('res.data:', res.data)
        },
        async createSharePage() {

            // 添加分享页
            let form = {
                userid: 500,
                goodids: JSON.stringify([{
                    goodId: 6,
                    purcharseLink: "https://pages.tmall.com/wow/an/cs/act/wupr?spm=a3204.7084713.1997320521.4.hl7dsm&wh_biz=tm&wh_pid=act%2F173d63dca19&disableNav=YES"
                },
                {
                    goodId: 5,
                    purcharseLink: "https://pages.tmall.com/wow/an/cs/act/wupr?spm=a3204.7084713.1997320521.4.hl7dsm&wh_biz=tm&wh_pid=act%2F173d63dca19&disableNav=YES"
                }]),
                createdate: '2022-11-30 17:24:50',
                status: "正常",
                field01: 'field01',
                field02: 'field02',
                field03: 'field03',
            }
            const { data: res } = await this.$http.post('qlgoodsShare', form)
            if (res.meta.status !== 201) {
                return this.$message.error(res.meta.msg)
            }
            this.$message.success(res.meta.msg)
        },
        async getGoodsSalesList() {

            const { data: res } = await this.$http.get('qlgoodsSales', {
                params: {
                    // query: {key:"",marr_type:'主星'},
                    creater: 500,
                    status: '已下单',
                },
            })
            console.log('res.data:', res.data)
        },
        async purchase() {
            // 添加商品
            let form = {
                order_id: 11,
                goods_id: 5,
                goods_price: 221,
                goods_number: 2,
                create_date: '2022-11-30 17:24:50',
                shop_id: 1,
                remark: 'remark',
                field02: 'field02',
                field01: 'field01',
                status: '已下单',
            }
            const { data: res } = await this.$http.post('qlgoodsSales', form)
            if (res.meta.status !== 201) {
                return this.$message.error(res.meta.msg)
            }
            this.$message.success(res.meta.msg)
        },
        async testApi(path, method, params) {
            console.log("testApi")
            switch (method) {
                case 'GET':
                    debugger
                    const { data: res } = await this.$http.get(path, { params: params })
                    if (res.meta.status !== 200) {
                        return this.$message.error('获取失败！')
                    }
                    switch (path) {
                        case 'wuXingSelectCar':
                            this.wuXingResult = res.data
                            break
                        default:
                            this.result = res.data

                    }

            }

        },
        async testApi2() {
            const { data: res } = await this.$http.get('pay/pay ', {})
            console.log(res.data)
        },
        async marrBaseDataXing() {

            const { data: res } = await this.$http.get('marrcomput', {
                params: {
                    // query: {key:"",marr_type:'主星'},
                    pagenum: 1,
                    pagesize: 1000,
                    birthday: this.person.birthday,
                    sex: this.person.sex,
                    city: this.person.city,
                    times: 1
                },
            })
            this.marrResult = res.data
        },

        async twoPersonPair() {

            const { data: res } = await this.$http.get('marrcomput/twoMarrPair', {
                params: {
                    // query: {key:"",marr_type:'主星'},
                    pagenum: 1,
                    pagesize: 1000,
                    birthdayOne: this.person.birthdayOne,
                    birthdayTwo: this.person.birthdayTwo,
                    oneSex: this.person.oneSex,
                    twoSex: this.person.twoSex
                },
            })
            this.twoPairResult = res.data
        }
    },
}


/* eslint-disable */
</script>
<style scoped>
.api-div>div>span {
    display: inline-block;
    width: 100px;
}
</style>
