<template>
  <div>
    <el-input v-model="announcement.context" placeholder="请填写通告内容"></el-input>
    <el-button type="primary" @click="update()" style="margin-top: 20px;">提交修改</el-button>
  </div>

</template>

<script>
  export default {
    data () {
      return {

        announcement:
        {
          id: 0,
          context: ""
        }

      }

    },
    created () {
      this.getData()
    },
    methods: {
      async getData () {
        const { data: res } = await this.$http.get('seeting')
        if (res.meta.status !== 200) {
          return this.$message.error(res.meta.msg)
        }
        this.$message.success(res.meta.msg)
        this.announcement = res.data.seeting[3]
      },

      async update () {
        let form = this.announcement

        const { data: res } = await this.$http.put(`seeting/${form.id}`, form)
        if (res.meta.status !== 200) {
          return this.$message.success('修改成功')
        }
        this.$message.success(res.meta.msg)
      }
    }
  }
</script>

<style lang="less" scoped>
</style>