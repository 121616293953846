<!-- eslint-disable vue/valid-v-else -->
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/pipeiCtr' }">小程序配置</el-breadcrumb-item>
      <el-breadcrumb-item>匹配控制</el-breadcrumb-item>
    </el-breadcrumb>

    <div
      style="display: flex;flex-direction:column;  align-items: flex-start;background-color: #FFF;padding: 25px;border-radius: 5px;margin-top: 20px;">

      <div style="display: flex; align-items:flex-start;">
        <div style="margin-bottom: 10px; font-weight: bold; margin-right: 25px;">查看爱情指数</div>
        <el-switch v-model="aqingzhishuOBJ.is_open" active-color="#13ce66" inactive-color="#E8E8E8">
        </el-switch>
      </div>
      <div>

        <div style="display: flex;  flex-direction:column;align-items:center">

          <el-slider style="width:500px;margin-left: 25px;" v-model="aqingzhishuOBJ.discount" :step="1" :min="0"
            :max="10" :disabled="!aqingzhishuOBJ.is_open" show-stops>
          </el-slider>
          <div v-if="aqingzhishuOBJ.is_open" style="font-weight: bold;font-size: 20px;">邀请成功优惠{{ aqingzhishuOBJ.discount
            }}0%</div>
          <div v-else style="font-weight: bold;font-size: 20px;">无需邀请好友</div>
          <el-button @click="update(aqingzhishuOBJ.pipeiScore)" type="warning" icon="el-icon-check" circle
            style="margin-top: 12px;"></el-button>
        </div>
      </div>
    </div>

    <div
      style="display: flex;flex-direction:column;  align-items: flex-start;background-color: #FFF;padding: 25px;border-radius: 5px;margin-top: 20px;">

      <div style="display: flex; align-items:flex-start;">
        <div style="margin-bottom: 10px; font-weight: bold; margin-right: 25px;">匹配度为100%的是否需要邀请好友解锁</div>
        <el-switch v-model="yibaiOBJ.is_open" active-color="#13ce66" inactive-color="#E8E8E8">
        </el-switch>
      </div>
      <div>

        <div style="display: flex;  flex-direction:column;align-items:center">

          <el-slider style="width:500px;margin-left: 25px;" v-model="yibaiOBJ.discount" :step="1" :min="0" :max="10"
            :disabled="!yibaiOBJ.is_open" show-stops>
          </el-slider>
          <div v-if="yibaiOBJ.is_open" style="font-weight: bold;font-size: 20px;">邀请成功优惠{{ yibaiOBJ.discount }}0%</div>
          <div v-else style="font-weight: bold;font-size: 20px;">无需邀请好友</div>
          <el-button @click="update(yibaiOBJ.pipeiScore)" type="warning" icon="el-icon-check" circle
            style="margin-top: 12px;"></el-button>
        </div>
      </div>
    </div>

    <div
      style="display: flex;flex-direction:column;  align-items: flex-start;background-color: #FFF;padding: 25px;  order-radius: 5px;margin-top: 20px;">

      <div style="display: flex; align-items:flex-start;">
        <div style="margin-bottom: 10px; font-weight: bold; margin-right: 25px;">匹配度为70%的是否需要邀请好友解锁</div>
        <el-switch v-model="qishiOBJ.is_open" active-color="#13ce66" inactive-color="#E8E8E8">
        </el-switch>
      </div>
      <div>

        <div style="display: flex;flex-direction:column; align-items:center">

          <el-slider style="width:500px;margin-left: 25px;" v-model="qishiOBJ.discount" :step="1" :min="0" :max="10"
            :disabled="!qishiOBJ.is_open" show-stops>
          </el-slider>
          <div v-if="qishiOBJ.is_open" style="font-weight: bold;font-size: 20px;">邀请成功优惠{{ qishiOBJ.discount }}0%</div>
          <div v-else="qishiOBJ.is_open" style="font-weight: bold;font-size: 20px;">无需邀请好友</div>
          <el-button @click="update(qishiOBJ.pipeiScore)" type="warning" icon="el-icon-check" circle
            style="margin-top: 12px;"></el-button>
        </div>
      </div>
    </div>

    <div
      style="display: flex;flex-direction:column;  align-items: flex-start;background-color: #FFF;padding: 25px;border-radius: 5px;margin-top: 20px;">

      <div style="display: flex; align-items:flex-start;">
        <div style="margin-bottom: 10px; font-weight: bold; margin-right: 25px;">匹配度为50%的是否需要邀请好友解锁</div>
        <el-switch v-model="wushiOBJ.is_open" active-color="#13ce66" inactive-color="#E8E8E8">
        </el-switch>
      </div>
      <div>

        <div style="display: flex;flex-direction:column; align-items:center">

          <el-slider style="width:500px;margin-left: 25px;" v-model="wushiOBJ.discount" :step="1" :min="0" :max="10"
            :disabled="!wushiOBJ.is_open" show-stops>
          </el-slider>
          <div v-if="wushiOBJ.is_open" style="font-weight: bold;font-size: 20px;">邀请成功优惠{{ wushiOBJ.discount }}0%</div>
          <div v-else="wushiOBJ.is_open" style="font-weight: bold;font-size: 20px;">无需邀请好友</div>
          <el-button @click="update(wushiOBJ.pipeiScore)" type="warning" icon="el-icon-check" circle
            style="margin-top: 12px;"></el-button>
        </div>
      </div>
    </div>

    <div
      style="display: flex;flex-direction:column;  align-items: flex-start;background-color: #FFF;padding: 25px;border-radius: 5px;margin-top: 20px;">

      <div style="display: flex; align-items:flex-start;">
        <div style="margin-bottom: 10px; font-weight: bold; margin-right: 25px;">匹配度为20%的是否需要邀请好友解锁</div>
        <el-switch v-model="ershiOBJ.is_open" active-color="#13ce66" inactive-color="#E8E8E8">
        </el-switch>
      </div>
      <div>

        <div style="display: flex;flex-direction:column; align-items:center">

          <el-slider style="width:500px;margin-left: 25px;" v-model="ershiOBJ.discount" :step="1" :min="0" :max="10"
            :disabled="!ershiOBJ.is_open" show-stops>
          </el-slider>
          <div v-if="ershiOBJ.is_open" style="font-weight: bold;font-size: 20px;">邀请成功优惠{{ ershiOBJ.discount }}0%</div>
          <div v-else="ershiOBJ.is_open" style="font-weight: bold;font-size: 20px;">无需邀请好友</div>
          <el-button @click="update(ershiOBJ.pipeiScore)" type="warning" icon="el-icon-check" circle
            style="margin-top: 12px;"></el-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      aqingzhishuOBJ: {
        id: 5,
        is_open: false,
        discount: 5,
        pipeiScore: 'zhishu'
      },

      yibaiOBJ: {
        id: 4,
        is_open: false,
        discount: 5,
        pipeiScore: 'yibai'
      },

      qishiOBJ: {
        id: 3,
        is_open: false,
        discount: 5,
        pipeiScore: 'qishi'
      },

      wushiOBJ: {
        id: 2,
        is_open: false,
        discount: 5,
        pipeiScore: 'wushi'
      },

      ershiOBJ: {
        id: 1,
        is_open: false,
        discount: 5,
        pipeiScore: 'ershi'
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const { data: res } = await this.$http.get('discount')
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.$message.success(res.meta.msg)
      res.data.discount = res.data.discount.map(item => {
        if (item.is_open === 1) {
          return { ...item, is_open: true };
        } else {
          return { ...item, is_open: false };
        }
      });
      this.aqingzhishuOBJ = res.data.discount[4];
      this.yibaiOBJ = res.data.discount[3];
      this.qishiOBJ = res.data.discount[2];
      this.wushiOBJ = res.data.discount[1];
      this.ershiOBJ = res.data.discount[0];
      console.log(this.yibaiOBJ)
      console.log(this.qishiOBJ)
      console.log(this.wushiOBJ)
      console.log(this.ershiOBJ)
    },

    async update(pipeiScore) {
      let editForm = {
        id: 0,
        is_open: false,
        discount: 5
      };
      if (pipeiScore === 'yibai') {
        editForm = {
          id: this.yibaiOBJ.id,
          is_open: this.yibaiOBJ.is_open ? 1 : 0,
          discount: this.yibaiOBJ.discount
        };
      } else if (pipeiScore === 'qishi') {
        editForm = {
          id: this.qishiOBJ.id,
          is_open: this.qishiOBJ.is_open ? 1 : 0,
          discount: this.qishiOBJ.discount
        }
      } else if (pipeiScore === 'wushi') {
        editForm = {
          id: this.wushiOBJ.id,
          is_open: this.wushiOBJ.is_open ? 1 : 0,
          discount: this.wushiOBJ.discount
        }
      } else if (pipeiScore === 'ershi') {
        editForm = {
          id: this.ershiOBJ.id,
          is_open: this.ershiOBJ.is_open ? 1 : 0,
          discount: this.ershiOBJ.discount
        }
      } else if (pipeiScore === 'zhishu') {
        editForm = {
          id: this.aqingzhishuOBJ.id,
          is_open: this.aqingzhishuOBJ.is_open ? 1 : 0,
          discount: this.aqingzhishuOBJ.discount
        }
      }
      console.log(editForm)
      const { data: res } = await this.$http.put(`discount/${editForm.id}`, editForm)
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.$message.success(res.meta.msg)
    }
  }
}
</script>

<style lang="less" scoped></style>
