<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->

            <el-breadcrumb-item><span>{{ panName }}</span>--星名全部资料</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-input placeholder="请输入搜索星名" v-model="queryInfo.query.key" clearable @clear="getUserList">
                        <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-button type="primary" @click="addDialog">添加星名</el-button>
                    <el-button type="primary" @click="deleteAll">清除所有</el-button>


                </el-col>
            </el-row>

            <el-table :data="userList" border stripe>
                <!-- <el-table-column type="index"></el-table-column> -->
                <el-table-column prop="pan_name" label="盘名" width="80px"></el-table-column>
                  <el-table-column prop="gongSet" label="宫位" width="80px"></el-table-column>
                <el-table-column prop="gong_set_name" label="宫位名" width="80px"></el-table-column>
               
                <el-table-column prop="xing_class" label="星类别" width="80px"></el-table-column>
                <el-table-column prop="xing_name" label="星名" width="120px"></el-table-column>
                <el-table-column prop="field01" label="发生四化星" width="120px"></el-table-column>
                <el-table-column prop="gong_set_huaru" label="化入宫位" width="120px"></el-table-column>
                <el-table-column prop="xing_readme" label="说明"></el-table-column>
                <el-table-column prop="field02" label="备注"></el-table-column>
                <el-table-column label="状态" width="80px">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.mg_state" @change="userStateChanged(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120px">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="showEditDialog(scope.row.id)"></el-button>
                        <el-button type="warning" icon="el-icon-delete" size="mini"
                            @click="delOpen(scope.row.id)"></el-button>
                        <!-- <el-button type="danger" icon="el-icon-setting" size="mini" @click="setRole(scope.row)"></el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页导航
      @size-change：对应pagesize改变
      @current-change：对应pagenum改变
      -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 2, 3, 4, 5]" :page-size="queryInfo.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </el-card>

        <!-- 添加星名弹框 -->
        <el-dialog title="添加星名" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
            <!-- 主体内容 -->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
                <el-form-item label="盘名称" prop="pan_name">
                    <el-input v-model="addForm.pan_name"></el-input>
                </el-form-item>
                <el-form-item label="星类别" prop="xing_class">
                    <el-input v-model="addForm.xing_class"></el-input>
                </el-form-item>
                 <el-table-column prop="gongSet" label="宫位" width="80px"></el-table-column>
                <el-form-item label="宫位名" prop="gong_set_name">
                    <el-input v-model="addForm.gong_set_name"></el-input>
                </el-form-item>
                <el-form-item label="星名称" prop="xing_name">
                    <el-input v-model="addForm.xing_name"></el-input>
                </el-form-item>
                <el-form-item label="发生四化星" prop="field01">
                    <el-input v-model="addForm.xing_name"></el-input>
                </el-form-item>
                <el-form-item label="化入宫位" prop="gong_set_huaru">
                    <el-input v-model="addForm.xing_name"></el-input>
                </el-form-item>
                <el-form-item label="星说明" prop="xing_readme">
                    <el-input v-model="addForm.xing_readme" type="textarea" :rows="5"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="field02">
                    <el-input v-model="addForm.field02" type="textarea" :rows="5"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 修改星名弹框 -->
        <el-dialog title="修改星名" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed">
            <!-- 主体内容 -->
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
                <el-form-item label="盘名称" prop="pan_name">
                    <el-input v-model="editForm.pan_name"></el-input>
                </el-form-item>
                <el-form-item label="星类别" prop="xing_class">
                    <el-input v-model="editForm.xing_class"></el-input>
                </el-form-item>
                 <el-table-column prop="gongSet" label="宫位" width="80px"></el-table-column>
                <el-form-item label="宫位名" prop="gong_set_name">
                    <el-input v-model="editForm.gong_set_name"></el-input>
                </el-form-item>
                <el-form-item label="星名称" prop="xing_name">
                    <el-input v-model="editForm.xing_name"></el-input>
                </el-form-item>
                <el-form-item label="发生四化星" prop="field01">
                    <el-input v-model="editForm.xing_name"></el-input>
                </el-form-item>
                <el-form-item label="化入宫位" prop="gong_set_huaru">
                    <el-input v-model="editForm.xing_name"></el-input>
                </el-form-item>
                <el-form-item label="星说明" prop="xing_readme">
                    <el-input v-model="editForm.xing_readme" type="textarea" :rows="5"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="field02">
                    <el-input v-model="editForm.field02" type="textarea" :rows="5"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="eidtUser">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="分配角色" :visible.sync="setRoleVisible" width="30%" @close="setRoleDialogClosed">
            <div>
                <p>当前星名：{{ usersInfo.username }}</p>
                <p>当前角色：{{ usersInfo.role_name }}</p>
                <p>
                    分配新角色：
                    <el-select v-model="selectRoleId" placeholder="请选择">
                        <el-option v-for="item in roleList" :key="item.id" :label="item.roleName"
                            :value="item.id"></el-option>
                    </el-select>
                </p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setRoleVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    data() {
        // var validateEmail = (rule, value, callback) => {
        //   const regEmail = /^\w+@\w+(\.\w+)+$/
        //   if (!regEmail.test(value)) {
        //     callback(new Error('请输入合法的邮箱'))
        //   }
        //   callback()
        // }
        // var validateMobile = (rule, value, callback) => {
        //   const regMobile = /^1[34578]\d{9}$/
        //   if (!regMobile.test(value)) {
        //     callback(new Error('请输入合法的电话'))
        //   }
        //   callback()
        // }
        return {
            // 星名列表数据
            userList: [],
            // 获取查询星名列表的参数
            queryInfo: {
                query: { key: "", mingPan: "" },
                pagenum: 1,
                pagesize: 140
            },
            // 星名总数量
            total: 0,
            // 是否显示添加星名弹窗
            addDialogVisible: false,
            // 添加星名对象
            addForm: {
                pan_name: '',
                gong_set_name: '',
                xing_class: '',
                xing_name: '',
                xing_readme: '',
                gong_set_huaru: '',
                gongSet:'',
                field01: '',
                field02: ''
            },
            addFormRules: {
                pan_name: [
                    { required: true, message: '请输入盘名称', trigger: 'blur' },
                    { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                ],
                gong_set_name: [
                    { required: true, message: '请输入宫位名称', trigger: 'blur' },
                    { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
                ],
                xing_name: [
                    { required: true, message: '星名名', trigger: 'blur' },
                    { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                ]
            },
            // 是否弹出修改星名弹框
            editDialogVisible: false,
            editForm: {
                mg_id: '',
                pan_name: '',
                gongSet:'',
                gong_set_name: '',
                gong_set_huaru: '',
                xing_class: '',
                xing_name: '',
                xing_readme: '',
                field01: '',
                field02: ''
            },
            editFormRules: {
                pan_name: [
                    { required: true, message: '请输入盘名', trigger: 'blur' }
                    // { validator: validateEmail, trigger: 'blur' }
                ],
                gong_set_name: [
                    { required: true, message: '请输入宫位名', trigger: 'blur' }
                    // { validator: validateMobile, trigger: 'blur' }
                ]
            },

            // 控制分配角色对话框显示或隐藏
            setRoleVisible: false,
            // 点击分配角色获取对应星名的信息
            usersInfo: {},
            // 角色列表
            roleList: [],
            // 角色下来框默认id
            selectRoleId: '',
            panName: ''
        }
    },
    computed: {
        userListFilter() {
            var filerList = this.userList.filter(obj => {
                return obj.pan_name == this.panName
            })
            return filerList
        }
    },
    created() {
        this.getUserList()
    },
    methods: {
        async deleteAll() {
            const { data: res } = await this.$http.post('starData/deletestar', { postData: [], delete: 'all' })
            if (res.meta.status !== 201) {
                console.log(res)
                return this.$message.error(res.meta.msg)
            }
            // 添加成功的提示
            this.userList = []
            this.$message.success('清除成功')
        },
        // 查询星名列表数据
        async getUserList() {
            const { data: res } = await this.$http.get('starData', { params: this.queryInfo })
            if (res.meta.status !== 200) {
                return this.$message.error('获取列表失败！')
            }
            this.userList = res.data.users
            this.total = res.data.total
        },
        // pagesize改变，从新请求
        handleSizeChange(newSize) {
            this.queryInfo.pagesize = newSize
            this.getUserList()
        },
        // pagenum 改变，重新请求
        handleCurrentChange(current) {
            this.queryInfo.pagenum = current
            this.getUserList()
        },
        // 修改星名状态，当星名点击状态切换按钮时，状态值会变更，这时保存到后台数据库
        async userStateChanged(row) {
            const { data: res } = await this.$http.put(`starData/${row.id}/state/${row.mg_state}`)
            // 如果更新出错
            if (res.meta.status !== 200) {
                // 修改前端页面中的星名状态
                row.mg_state = !row.mg_state
                return this.$message.error('状态更新失败！')
            }
            this.$message.success('状态更新成功')
        },
        // 添加星名弹框
        addDialog() {
            // 首先设置addDialogVisible为true，显示弹窗
            this.addDialogVisible = true
        },
        // 关闭添加星名弹框后，重置所有字段
        addDialogClosed() {
            this.$refs.addFormRef.resetFields()
        },
        // 点击确定时添加星名弹框
        addUser() {
            // 首先获取添加星名输入框对象，获取验证是否通过
            this.$refs.addFormRef.validate(async valide => {
                if (!valide) {
                    this.$message.error('请填写完整信息')
                }
                // 发送请求完成添加星名的操作
                const { data: res } = await this.$http.post('starData', this.addForm)
                if (res.meta.status !== 201) {
                    console.log(res)
                    return this.$message.error(res.meta.msg)
                }
                // 添加成功的提示
                this.$message.success('添加成功')
                // 关闭对话框
                this.addDialogVisible = false
                // 重新请求最新的数据
                this.getUserList()
            })
        },
        // 根据星名id查询星名信息，用作修改弹框
        async showEditDialog(id) {
            const { data: res } = await this.$http.get('starData/' + id)
            if (res.meta.status !== 200) {
                return this.$message.error(res.meta.msg)
            }
            // 查询成功，将星名数据保存到editForm 中
            this.editForm = res.data
            // 显示弹框
            this.editDialogVisible = true
        },
        // 关闭修改星名弹窗时，清空表单字段
        editDialogClosed() {
            this.$refs.editFormRef.resetFields()
        },
        // 修改星名信息
        eidtUser() {
            // 首先验证表单合法
            this.$refs.editFormRef.validate(async valide => {
                if (!valide) {
                    this.$message.error('请填写完整信息')
                }
                const { data: res } = await this.$http.put('starData/' + this.editForm.mg_id, this.editForm)
                console.log(res)
                if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
                this.$message.success(res.meta.msg)
                // 关闭弹框
                this.editDialogVisible = false
                // 重新请求最新数据
                this.getUserList()
            })
        },
        async delOpen(id) {
            // 确认时，接收的confirmResult为confirm ,取消时 confirmResult为cancel
            const confirmResult = await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            // 点击取消删除
            if (confirmResult !== 'confirm') return this.$message.info('已取消删除')
            // 确认删除
            const { data: res } = await this.$http.delete('starData/' + id)
            if (res.meta.status !== 200) {
                return this.$message.error(res.meta.msg)
            }
            this.$message.success('删除成功')
            // 重新请求最新数据
            this.getUserList()
        },
        // 设置角色
        async setRole(usersInfo) {
            // 获取星名信息
            this.usersInfo = usersInfo
            // 获取所有角色列表
            const { data: res } = await this.$http.get('roles')
            if (res.meta.status !== 200) {
                return this.$message.error('获取角色列表失败！')
            }
            this.roleList = res.data
            this.setRoleVisible = true
        },
        // 点击确定保存新选择的角色
        async saveRoleInfo() {
            // 判断是否选择了新的角色
            if (!this.selectRoleId) {
                return this.$message.error('请选择需要分配的角色')
            }

            // 保存数据到数据库
            const { data: res } = await this.$http.put(`starData/${this.usersInfo.id}/role`, { rid: this.selectRoleId })
            if (res.meta.status !== 200) {
                return this.$message.error(res.meta.msg)
            }
            this.$message.success('分配角色成功')
            // 重新查询星名列表数据
            this.getUserList()
            // 关闭对话框
            this.setRoleVisible = false
        },
        // 关闭分配权限对话框触发
        setRoleDialogClosed() {
            // 重置下拉框的内容
            this.selectRoleId = ''
            this.userInfo = {}
        }
    }
}
/* eslint-disable */
</script>
