<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @clear="getUserList">
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="addDialog">添加管理员</el-button>
        </el-col>
      </el-row>

      <el-table :data="userList" border stripe>
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="username" label="姓名"></el-table-column>
        <el-table-column prop="role_name" label="角色"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            <el-button type="warning" icon="el-icon-delete" size="mini" @click="delOpen(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-setting" size="mini" @click="setRole(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航
      @size-change：对应pagesize改变
      @current-change：对应pagenum改变
      -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[1, 2, 3, 4, 5]" :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加用户弹框 -->
    <el-dialog title="添加管理员" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
      <!-- 主体内容 -->
      <el-form :model="addForm" label-width="70px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="Confirmpassword">
          <el-input v-model="addForm.Confirmpassword" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div class="img-list">
        <div class="img-content" v-for="(item,key) in editForm.field01" :key="key">
          <img :src="imgBaseUrl+item">
          <!-- <div class="name">
                <div>{{ item.name }}</div>
                <el-button type="text" @click="handleFileName(item,key)">修改名字</el-button>
            </div> -->
          <!-- 删除icon -->
          <div class="del">
            <!-- <i @click="handleFileRemove(item,key)" class="el-icon-delete2"></i> -->
            <span @click="handleFileRemove(item,key)">删除</span>
          </div>
          <!-- 放大icon -->
          <div class="layer" @click="handleFileEnlarge(item)">
            <i class="el-icon-view"></i>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户弹框 -->
    <el-dialog title="修改用户" :visible.sync="editDialogVisible" width="80%" @close="editDialogClosed">
      <!-- 主体内容 -->
      <el-form :model="editForm" label-width="70px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="editForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="Confirmpassword">
          <el-input v-model="editForm.Confirmpassword" type="password"></el-input>
        </el-form-item>
      </el-form>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="eidtUser">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="分配角色" :visible.sync="setRoleVisible" width="30%" @close="setRoleDialogClosed">
      <div>
        <p>当前用户：{{usersInfo.username}}</p>
        <p>当前角色：{{usersInfo.role_name}}</p>
        <p>
          分配新角色：
          <el-select v-model="selectRoleId" placeholder="请选择">
            <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
          </el-select>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  /* eslint-disable */
  export default {
    data () {


      return {

        // 图片上传使用内部封装的axjx，需要为其指定token
        headersObj: {
          Authorization: window.sessionStorage.getItem('token')
        },


        // 用户列表数据
        userList: [],
        // 获取查询用户列表的参数
        queryInfo: {
          query: '',
          pagenum: 1,
          pagesize: 50,
          role_id:5, 
          is_mag: true
        },
        // 用户总数量
        total: 0,
        // 是否显示添加用户弹窗
        addDialogVisible: false,
        // 添加用户对象
        addForm: {
          username: '',
          password: '',
          Confirmpassword: '',
        },
        // 是否弹出修改用户弹框
        editDialogVisible: false,
        editForm: {
          id: '',
          username: '',
          password: '',
          Confirmpassword: '',
        },


        // 控制分配角色对话框显示或隐藏
        setRoleVisible: false,
        // 点击分配角色获取对应用户的信息
        usersInfo: {},
        // 角色列表
        roleList: [],
        // 角色下来框默认id
        selectRoleId: ''
      }
    },
    created () {
      this.getUserList()
    },

    methods: {

      // 查询用户列表数据
      async getUserList () {
        const { data: res } = await this.$http.get('users', { params: this.queryInfo })
        if (res.meta.status !== 200) {
          return this.$message.error('获取用户列表失败！')
        }
        this.userList = res.data.users
        this.total = res.data.total
      },
      // pagesize改变，从新请求
      handleSizeChange (newSize) {
        this.queryInfo.pagesize = newSize
        this.getUserList()
      },
      // pagenum 改变，重新请求
      handleCurrentChange (current) {
        this.queryInfo.pagenum = current
        this.getUserList()
      },
      // 修改用户状态，当用户点击状态切换按钮时，状态值会变更，这时保存到后台数据库
      async userStateChanged (row) {
        const { data: res } = await this.$http.put(`users/${row.id}/state/${row.mg_state}`)
        // 如果更新出错
        if (res.meta.status !== 200) {
          // 修改前端页面中的用户状态
          row.mg_state = !row.mg_state
          return this.$message.error('用户状态更新失败！')
        }
        this.$message.success('用户状态更新成功')
      },
      // 添加用户弹框
      addDialog () {
        // 首先设置addDialogVisible为true，显示弹窗
        this.addDialogVisible = true
      },
      // 关闭添加用户弹框后，重置所有字段
      addDialogClosed () {
        this.$refs.addFormRef.resetFields()
      },
      // 点击确定时添加用户弹框
      addUser () {
        if (!(this.addForm.password === this.addForm.Confirmpassword)) {
          return this.$message.error('输入的密码不一致')
        }
        else {
          this.$http.post('users/mguser', {
            "username": this.addForm.username,
            "password": this.addForm.password,
            "rid": 5
          }).then(res => {
            if (res.data.meta.status !== 201) {

              return this.$message.error(res.data.meta.msg)
            }
            // 添加成功的提示
            this.$message.success('添加用户成功')
            // 关闭对话框
            this.addDialogVisible = false
            // 重新请求最新的数据
            this.getUserList()
          })


        }

      },
      // 根据用户id查询用户信息，用作修改弹框
      async showEditDialog (id) {
        const { data: res } = await this.$http.get('users/' + id)
        if (res.meta.status !== 200) {
          return this.$message.error(res.meta.msg)
        }
        // 查询成功，将用户数据保存到editForm 中
        this.editForm = res.data
        // 显示弹框
        this.editDialogVisible = true
      },
      // 关闭修改用户弹窗时，清空表单字段
      editDialogClosed () {
        this.$refs.uploadedit.clearFiles()
        this.$refs.editFormRef.resetFields()
      },
      // 修改用户信息
      eidtUser () {
        console.log('this.editForm.password', this.editForm.password)
        console.log('this.editForm.Confirmpassword', this.editForm.Confirmpassword)
        console.log(!this.editForm.password == this.editForm.Confirmpassword)
        if (!(this.editForm.password == this.editForm.Confirmpassword)) {
          return this.$message.error('输入的密码不一致')
        }
        else {
          console.log(this.editForm)
          this.$http.put('users/' + this.editForm.id, {
            "password": this.editForm.password,
          }).then(res => {
            if (res.data.meta.status !== 200) return this.$message.error(res.data.meta.msg)
            this.$message.success(res.data.meta.msg)
            // 关闭弹框
            this.editDialogVisible = false
            // 重新请求最新数据
            this.getUserList()
          })


        }
      },
      async delOpen (id) {
        // 确认时，接收的confirmResult为confirm ,取消时 confirmResult为cancel
        const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

        // 点击取消删除
        if (confirmResult !== 'confirm') return this.$message.info('已取消删除')
        // 确认删除
        const { data: res } = await this.$http.delete('users/' + id)
        if (res.meta.status !== 200) {
          return this.$message.error(res.meta.msg)
        }
        this.$message.success('删除成功')
        // 重新请求最新数据
        this.getUserList()
      },
      // 设置角色
      async setRole (usersInfo) {
        // 获取用户信息
        this.usersInfo = usersInfo
        // 获取所有角色列表
        const { data: res } = await this.$http.get('roles')
        if (res.meta.status !== 200) {
          return this.$message.error('获取角色列表失败！')
        }
        this.roleList = res.data
        this.setRoleVisible = true
      },
      // 点击确定保存新选择的角色
      async saveRoleInfo () {
        // 判断是否选择了新的角色
        if (!this.selectRoleId) {
          return this.$message.error('请选择需要分配的角色')
        }

        // 保存数据到数据库
        const { data: res } = await this.$http.put(`users/${this.usersInfo.id}/role`, { rid: this.selectRoleId })
        if (res.meta.status !== 200) {
          return this.$message.error(res.meta.msg)
        }
        this.$message.success('分配角色成功')
        // 重新查询用户列表数据
        this.getUserList()
        // 关闭对话框
        this.setRoleVisible = false
      },
      // 关闭分配权限对话框触发
      setRoleDialogClosed () {
        // 重置下拉框的内容
        this.selectRoleId = ''
        this.userInfo = {}
      }
    }
  }
  /* eslint-disable */
</script>
<style scoped>
  .previewImg {
    width: 100%;
  }

  * {
    box-sizing: border-box;
  }

  .img-list {
    overflow: hidden;
    width: 100%;
  }

  .img-list .img-content {
    float: left;
    position: relative;
    display: inline-block;
    width: 200px;
    height: 270px;
    padding: 5px;
    margin: 5px 20px 20px 0;
    border: 1px solid #d1dbe5;
    border-radius: 4px;
    transition: all .3s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
  }

  .img-list .img-content img {
    display: block;
    width: 100%;
    height: 190px;
    margin: 0 auto;
    border-radius: 4px;
  }

  .img-list .img-content .name {
    margin-top: 10px;
  }

  .img-list .img-content .name>div {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 25px;
    line-height: 25px;
  }

  .img-list .img-content:hover .del,
  .img-list .img-content:hover .layer {
    opacity: 1;
  }

  .img-list .img-content .del,
  .img-list .img-content .layer {
    opacity: 0;
    transition: all .3s;
  }

  .img-list .img-content .del {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #8492a6;
    cursor: pointer;
    font-size: 1.1em;
  }

  .img-list .img-content .layer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 200px;
    color: #fff;
    text-align: center;
    z-index: 5;
    background-color: rgba(0, 0, 0, .4);
  }

  .img-list .img-content .layer i {
    font-size: 1.6em;
    margin-top: 80px;
  }
</style>