<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理1</el-breadcrumb-item>
            <el-breadcrumb-item>用户列表2</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @clear="getUserList">
                        <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                    </el-input>
                </el-col>
                <!-- <el-col :span="8">
                    主星 <el-input placeholder="请输入主星" v-model="queryInfo.query" clearable @clear="getUserList">
                        <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                    </el-input>
                </el-col> -->
                <el-col :span="2">
                    <el-button type="primary" @click="addDialog">添加用户</el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="danger" @click="delArray">批量删除</el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="downXls">导出列表</el-button>
                </el-col>
            </el-row>




            <el-table ref="multipleTable" @selection-change="selectL" :data="sortUserList" border stripe>

                <el-table-column type="selection" align="center" width="50"></el-table-column>
                <el-table-column prop="field03" label="主星"></el-table-column>
                <el-table-column prop="username" label="姓名" align="center"> </el-table-column>
                <el-table-column prop="user_sex" label="性别" width="50" align="center"></el-table-column>
                <el-table-column prop="birthday" label="生日" align="center" width="200"></el-table-column>
                <el-table-column prop="mobile" label="电话" align="center" width="120"></el-table-column>
                <el-table-column prop="marr" label="婚姻状态" width="100" align="center"></el-table-column>
                <el-table-column prop="bornaddress" label="出生地" align="center"></el-table-column>
                <el-table-column prop="wxno" label="微信号" align="center"></el-table-column>
                <el-table-column prop="height" label="身高" align="center"></el-table-column>
                <el-table-column prop="weight" label="体重" align="center"></el-table-column>
                <el-table-column prop="id_card_no" label="身份证" align="center"></el-table-column>
                <el-table-column prop="wages" label="年收入" align="center"></el-table-column>
                <el-table-column prop="pay_status" label="支付状态"></el-table-column>
                <el-table-column prop="role_name" label="角色" align="center"></el-table-column>
                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.mg_state" @change="userStateChanged(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="showEditDialog(scope.row.id)"></el-button>
                        <el-button type="warning" icon="el-icon-delete" size="mini"
                            @click="delOpen(scope.row.id)"></el-button>
                        <el-button type="danger" icon="el-icon-setting" size="mini"
                            @click="setRole(scope.row)"></el-button>
                    </template>
                </el-table-column>
            </el-table>





            <!-- 分页导航
      @size-change：对应pagesize改变
      @current-change：对应pagenum改变
      -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 2, 3, 4, 5]" :page-size="queryInfo.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </el-card>

        <!-- 添加用户弹框 -->
        <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
            <!-- 主体内容 -->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
                <el-form-item label="名称" prop="username">
                    <el-input v-model="addForm.username"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="addForm.password" type="password"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="addForm.email"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="mobile">
                    <el-input v-model="addForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="user_sex">
                    <el-input v-model="addForm.user_sex"></el-input>
                </el-form-item>
                <el-form-item label="生日" prop="birthday">
                    <el-date-picker v-model="addForm.birthday" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="婚姻状态" prop="marr">
                    <el-input v-model="addForm.marr"></el-input>
                </el-form-item>
                <el-form-item label="出生地" prop="bornaddress">
                    <el-input v-model="addForm.bornaddress"></el-input>
                </el-form-item>
                <el-form-item label="微信号" prop="wxno">
                    <el-input v-model="addForm.wxno"></el-input>
                </el-form-item>
                <el-form-item label="身高" prop="height">
                    <el-input v-model="addForm.height"></el-input>
                </el-form-item>
                <el-form-item label="体重" prop="weight">
                    <el-input v-model="addForm.weight"></el-input>
                </el-form-item>
                <el-form-item label="年收入" prop="wages">
                    <el-input v-model="addForm.wages"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="id_card_no">
                    <el-input v-model="addForm.id_card_no"></el-input>
                </el-form-item>
                <el-form-item label="支付状态" prop="pay_status">
                    <el-input v-model="addForm.pay_status"></el-input>
                </el-form-item>
                <el-form-item label="照片" prop="field01">
                    <!-- <el-input type='textarea' v-model="addForm.field01"></el-input> -->
                    <el-upload class="upload-demo" :action="imgUploadURL" :on-preview="handlePreview"
                        :on-remove="handleRemove" list-type="picture" :headers="headersObj" ref='upload'
                        :file-list="fileList" :on-success="handleSuccess"> <el-button size="small"
                            type="primary">点击上传</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div class="img-list">
                <div class="img-content" v-for="(item, key) in editForm.field01" :key="key">
                    <img :src="imgBaseUrl + item">
                    <!-- <div class="name">
                <div>{{ item.name }}</div>
                <el-button type="text" @click="handleFileName(item,key)">修改名字</el-button>
            </div> -->
                    <!-- 删除icon -->
                    <div class="del">
                        <!-- <i @click="handleFileRemove(item,key)" class="el-icon-delete2"></i> -->
                        <span @click="handleFileRemove(item, key)">删除</span>
                    </div>
                    <!-- 放大icon -->
                    <div class="layer" @click="handleFileEnlarge(item)">
                        <i class="el-icon-view"></i>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="" :visible.sync="isEnlargeImage" size="large" :modal-append-to-body="false" top="8%"
            width="60%">
            <img @click="isEnlargeImage = false" style="width:100%;" :src="imgBaseUrl + enlargeImage">
        </el-dialog>
        <!-- 修改用户弹框 -->
        <el-dialog title="修改用户" :visible.sync="editDialogVisible" width="80%" @close="editDialogClosed">
            <!-- 主体内容 -->
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
                <el-form-item label="名称" prop="username">
                    <el-input v-model="editForm.username" disabled></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="editForm.email"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="mobile">
                    <el-input v-model="editForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="user_sex">
                    <el-input v-model="editForm.user_sex"></el-input>
                </el-form-item>
                <el-form-item label="生日" prop="birthday">
                    <el-date-picker v-model="editForm.birthday" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="婚姻状态" prop="marr">
                    <el-input v-model="editForm.marr"></el-input>
                </el-form-item>
                <el-form-item label="出生地" prop="bornaddress">
                    <el-input v-model="editForm.bornaddress"></el-input>
                </el-form-item>
                <el-form-item label="微信号" prop="wxno">
                    <el-input v-model="editForm.wxno"></el-input>
                </el-form-item>
                <el-form-item label="身高" prop="height">
                    <el-input v-model="editForm.height"></el-input>
                </el-form-item>
                <el-form-item label="体重" prop="weight">
                    <el-input v-model="editForm.weight"></el-input>
                </el-form-item>
                <el-form-item label="年收入" prop="wages">
                    <el-input v-model="editForm.wages"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="id_card_no">
                    <el-input v-model="editForm.id_card_no"></el-input>
                </el-form-item>
                <el-form-item label="支付状态" prop="pay_status">
                    <el-input v-model="editForm.pay_status"></el-input>
                </el-form-item>
                <el-form-item label="照片" prop="field01">
                    <!-- <el-input type='textarea' v-model="addForm.field01"></el-input> -->
                    <el-upload class="upload-demo" :action="imgUploadURL" :on-preview="handlePreview"
                        :on-remove="handleRemove" list-type="picture" ref='uploadedit' :headers="headersObj"
                        :on-success="handleSuccess"> <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div class="img-list">
                <div class="img-content" v-for="(item, key) in editForm.field01" :key="key">
                    <img :src="imgBaseUrl + item">
                    <!-- <div class="name">
                <div>{{ item.name }}</div>
                <el-button type="text" @click="handleFileName(item,key)">修改名字</el-button>
            </div> -->
                    <!-- 删除icon -->
                    <div class="del">
                        <!-- <i @click="handleFileRemove(item,key)" class="el-icon-delete2"></i> -->
                        <span @click="handleFileRemove(item, key)">删除</span>
                    </div>
                    <!-- 放大icon -->
                    <div class="layer" @click="handleFileEnlarge(item)">
                        <i class="el-icon-view"></i>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="eidtUser">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="分配角色" :visible.sync="setRoleVisible" width="30%" @close="setRoleDialogClosed">
            <div>
                <p>当前用户：{{ usersInfo.username }}</p>
                <p>当前角色：{{ usersInfo.role_name }}</p>
                <p>
                    分配新角色：
                    <el-select v-model="selectRoleId" placeholder="请选择">
                        <el-option v-for="item in roleList" :key="item.id" :label="item.roleName"
                            :value="item.id"></el-option>
                    </el-select>
                </p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setRoleVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="预览图片" :visible.sync="previewImgVisible" width="50%">
            <img :src="previewPath" alt class="previewImg" />
        </el-dialog>
    </div>
</template>

<script>
/* eslint-disable */
import * as XLSX from "xlsx/xlsx.mjs";
export default {
    data() {
        var validateEmail = (rule, value, callback) => {
            const regEmail = /^\w+@\w+(\.\w+)+$/
            if (!regEmail.test(value)) {
                callback(new Error('请输入合法的邮箱'))
            }
            callback()
        }
        var validateMobile = (rule, value, callback) => {
            const regMobile = /^1[34578]\d{9}$/
            if (!regMobile.test(value)) {
                callback(new Error('请输入合法的电话'))
            }
            callback()
        }
        return {
            isEnlargeImage: false,//放大图片
            enlargeImage: '',//放大图片地址
            imgUploadURL: 'https://api.hyxz.cc/api/private/v1/upload',
            imgBaseUrl: 'https://api.hyxz.cc/',
            // imgUploadURL: 'https://127.0.0.1:8888/api/private/v1/upload',
            // 图片上传使用内部封装的axjx，需要为其指定token
            headersObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            fileList: [],

            // 图片预览地址
            previewPath: '',
            // 是否显示图片预览对话框
            previewImgVisible: false,
            // 用户列表数据
            userList: [],
            // 获取查询用户列表的参数
            queryInfo: {
                query: '',
                role_id: 0,
                pagenum: 1,
                pagesize: 1000
            },
            // 用户总数量
            total: 0,
            // 是否显示添加用户弹窗
            addDialogVisible: false,
            // 添加用户对象
            addForm: {
                username: '',
                password: '',
                email: '',
                mobile: '',
                user_sex: '',
                birthday: '',
                marr: '',
                bornaddress: '',
                wxno: '',
                height: '',
                weight: '',
                wages: '',
                id_card_no: '',
                pay_status: '',
                field01: [],
                field02: '',
                field03: ''
            },
            addFormRules: {
                username: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { validator: validateEmail, trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { validator: validateMobile, trigger: 'blur' }
                ]
            },
            // 是否弹出修改用户弹框
            editDialogVisible: false,
            editForm: {
                id: '',
                username: '',
                email: '',
                mobile: '',
                user_sex: '',
                birthday: '',
                marr: '',
                bornaddress: '',
                wxno: '',
                height: '',
                weight: '',
                wages: '',
                id_card_no: '',
                pay_status: '',
                field01: [],
                field02: '',
                field03: ''
            },
            editFormRules: {
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { validator: validateEmail, trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { validator: validateMobile, trigger: 'blur' }
                ]
            },

            // 控制分配角色对话框显示或隐藏
            setRoleVisible: false,
            // 点击分配角色获取对应用户的信息
            usersInfo: {},
            // 角色列表
            roleList: [],
            // 角色下来框默认id
            selectRoleId: ''
        }
    },
    created() {
        this.getUserList()
    },
    computed: {
        sortUserList() {
            let list = this.userList

            let resList = []

            // 使用 reduce 方法进行分组
            var groupedArray = list.reduce(function (acc, currentItem) {
                // 获取当前元素的分类
                var category = currentItem.field03;

                // 如果已经存在该分类，则将当前元素添加到对应的数组中
                if (acc[category]) {
                    acc[category].push(currentItem);
                } else { // 否则创建一个新的数组
                    acc[category] = [currentItem];
                }

                return acc;
            }, {});

            console.log(groupedArray);


            for (let key in groupedArray) {
                let tempList = groupedArray[key].sort((a, b) => {
                    return a.birthday - b.birthday
                })
                for (let i = 0; i < tempList.length; i++) {
                    resList.push(tempList[i])
                }
            }

            return resList
        }

    },

    methods: {
        downXls() {

            console.log(this.sortUserList)
            let data = this.sortUserList
            for (let d = 0; d < data.length; d++) {
                try {
                    data[d].xingMing = data[d].field03[0]
                } catch (error) {
                    data[d].xingMing = ''
                }


            }

            console.log('data', data)

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "data.xlsx");
        },
        // 查询星名列表数据
        async getZhiXing(row) {
            console.log(row)
            let birthDate = new Date(this.value1)
            console.log(birthDate)
            //      queryInfo: {
            //     birthday: '1976-11-14-10',
            //     sex: '男'
            //   },
            let birDayString = birthDate.getFullYear() + '-' + (birthDate.getMonth() + 1) + '-' + birthDate.getDate() + '-' + birthDate.getHours()
            console.log(birDayString)
            this.queryInfo = {
                birthday: birDayString,
                sex: '男'
            }
            const { data: res } = await this.$http.get('panData', { params: this.queryInfo })
            if (res.meta.status !== 200) {
                return 'xxx'
                return this.$message.error('获取列表失败！')
            }
            else {
                return 'yyy'
            }
            this.panData = res.data

        },
        handleFileEnlarge(_url) {//放大图片
            console.log(_url)
            if (_url) {
                this.enlargeImage = _url;
                this.isEnlargeImage = !this.isEnlargeImage;
            }
        },
        handleFileRemove(file, i) {//删除图片
            console.log(file, i)
            if (!file) {
                return false;
            }
            let that = this;
            this.$confirm('是否删除此附件？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //可添加ajax
                this.$message.success("删除成功")
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    onClose: () => {
                        that.editForm.field01.splice(i, 1)
                    }
                })
            }).catch((meg) => console.log(meg))
        },
        // 点击图片预览触发
        handlePreview(file) {
            this.previewPath = file.response.data.url
            this.previewImgVisible = true
        },
        // 移除图片触发
        handleRemove(file) {
            console.log(file)
            // 获取图片在服务器上的临时地址
            const uploadURL = file.response.data.tmp_path
            // 根据图片地址查找其在addForm的pics中的下标
            // const i = this.addForm.pics.findIndex(item =>
            //   item.pic === uploadURL
            // )
            // 删除对应下标项
            // this.addForm.pics.splice(i, 1)
            console.log(uploadURL)
        },
        // 上传成功触发
        handleSuccess(response) {
            // 将返回的图片的临时地址保存到商品表单中
            const picURL = {
                pic: response.data.tmp_path
            }
            // this.addForm.pics.push(picURL)

            if (this.editDialogVisible) {
                this.editForm.field01.push(picURL.pic)
            }
            else {
                this.addForm.field01.push(picURL.pic)
            }

            // this.fileList.push({
            //   name:picURL.pic,
            //   url:picURL.pic
            // })

            console.log(picURL)
        },
        // 查询用户列表数据
        async getUserList() {
            this.queryInfo.query = encodeURIComponent(this.queryInfo.query)


            for (let i = 1; i < 10000; i++) {
                this.queryInfo.pagenum = i
                this.queryInfo.pagesize = 500
                console.log('this.queryInfo', this.queryInfo)
                const { data: res } = await this.$http.get('users', { params: this.queryInfo })
                if (res.meta.status !== 200) {
                    return this.$message.error('获取用户列表失败！')
                }

            }

            const { data: res } = await this.$http.get('users', { params: this.queryInfo })
            if (res.meta.status !== 200) {
                return this.$message.error('获取用户列表失败！')
            }


            // this.userList = res.data.users
            this.userList = res.data.users.map(user => {
                // 获取 birthday 属性的值  
                const birthdayISOString = user.birthday;

                // 将 ISO 8601 格式的日期字符串转换为 JavaScript Date 对象  
                const birthdayDate = new Date(birthdayISOString);

                // 将 Date 对象格式化为 'YYYY-MM-DD' 格式  
                const formattedBirthday = `${birthdayDate.getFullYear()}-${padZero(birthdayDate.getMonth() + 1)}-${padZero(birthdayDate.getDate())}-${padZero(birthdayDate.getHours())}`;

                // 返回一个新的对象，其中包含已格式化的 birthday 属性  
                return {
                    ...user,
                    birthday: formattedBirthday
                };
            });

            function padZero(value) {
                return String(value).padStart(2, '0');
            }

            console.log(" this.userList", this.userList)

            this.total = res.data.total
        },

        // pagesize改变，从新请求
        handleSizeChange(newSize) {
            this.queryInfo.pagesize = newSize
            this.getUserList()
        },
        // pagenum 改变，重新请求
        handleCurrentChange(current) {
            this.queryInfo.pagenum = current
            this.getUserList()
        },
        // 修改用户状态，当用户点击状态切换按钮时，状态值会变更，这时保存到后台数据库
        async userStateChanged(row) {
            const { data: res } = await this.$http.put(`users/${row.id}/state/${row.mg_state}`)
            // 如果更新出错
            if (res.meta.status !== 200) {
                // 修改前端页面中的用户状态
                row.mg_state = !row.mg_state
                return this.$message.error('用户状态更新失败！')
            }
            this.$message.success('用户状态更新成功')
        },
        // 添加用户弹框
        addDialog() {
            // 首先设置addDialogVisible为true，显示弹窗
            this.addDialogVisible = true
        },
        // 关闭添加用户弹框后，重置所有字段
        addDialogClosed() {
            this.$refs.addFormRef.resetFields()
        },
        // 点击确定时添加用户弹框
        addUser() {
            // 首先获取添加用户输入框对象，获取验证是否通过
            this.$refs.addFormRef.validate(async valide => {
                if (!valide) {
                    this.$message.error('请填写完整用户信息')
                }
                // 发送请求完成添加用户的操作
                let submitData = Object.assign(this.addForm);
                submitData.field01 = JSON.stringify(submitData.field01)
                const { data: res } = await this.$http.post('users', submitData)
                if (res.meta.status !== 201) {
                    console.log(res)
                    return this.$message.error(res.meta.msg)
                }
                // 添加成功的提示
                this.$message.success('添加用户成功')
                // 关闭对话框
                this.addDialogVisible = false
                // 重新请求最新的数据
                this.getUserList()
            })
        },
        // 根据用户id查询用户信息，用作修改弹框
        async showEditDialog(id) {
            const { data: res } = await this.$http.get('users/' + id)
            if (res.meta.status !== 200) {
                return this.$message.error(res.meta.msg)
            }
            // 查询成功，将用户数据保存到editForm 中
            this.editForm = res.data
            this.editForm.birthday = new Date(this.editForm.birthday)

            this.editForm.field01 = JSON.parse(this.editForm.field01)
            this.fileList = []
            try {
                for (let i = 0; i < this.editForm.field01.length; i++) {
                    this.fileList.push({
                        name: this.editForm.field01[i],
                        url: this.editForm.field01[i]
                    })
                }

            } catch (error) {
                console.log(error)
            }


            // 显示弹框
            this.editDialogVisible = true
        },
        // 关闭修改用户弹窗时，清空表单字段
        editDialogClosed() {
            this.$refs.uploadedit.clearFiles()
            this.$refs.editFormRef.resetFields()
        },
        // 修改用户信息
        eidtUser() {
            // 首先验证表单合法
            this.$refs.editFormRef.validate(async valide => {
                if (!valide) {
                    this.$message.error('请填写完整用户信息')
                }
                let submitData = Object.assign(this.editForm);
                submitData.field01 = JSON.stringify(submitData.field01)
                const { data: res } = await this.$http.put('users/' + this.editForm.id, this.editForm)
                console.log(res)
                if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
                this.$message.success(res.meta.msg)
                // 关闭弹框
                this.editDialogVisible = false
                // 重新请求最新数据
                this.getUserList()
            })
        },
        async delOpen(id) {
            // 确认时，接收的confirmResult为confirm ,取消时 confirmResult为cancel
            const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            // 点击取消删除
            if (confirmResult !== 'confirm') return this.$message.info('已取消删除')
            // 确认删除
            const { data: res } = await this.$http.delete('users/' + id)
            if (res.meta.status !== 200) {
                return this.$message.error(res.meta.msg)
            }
            this.$message.success('删除成功')
            // 重新请求最新数据
            this.getUserList()
        },
        // 批量删除方法
        async delArray() {
            console.log(this.listSelectId)
            // 确认时，接收的confirmResult为confirm ,取消时 confirmResult为cancel
            const confirmResult = await this.$confirm('此操作将永久删除选中用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            // 点击取消删除
            if (confirmResult !== 'confirm') return this.$message.info('已取消删除')
            // 确认删除
            const { data: res } = await this.$http.post('users/batchdelete', { ids: this.listSelectId })
            if (res.meta.status !== 200) {
                return this.$message.error(res.meta.msg)
            }
            this.$message.success('删除成功')
            // 重新请求最新数据
            this.getUserList()
        },
        // 选中添加/不选中删除
        selectL(selection, row) {
            this.listSelectId = selection.map(item => item.id)

        },

        // 设置角色
        async setRole(usersInfo) {
            // 获取用户信息
            this.usersInfo = usersInfo
            // 获取所有角色列表
            const { data: res } = await this.$http.get('roles')
            if (res.meta.status !== 200) {
                return this.$message.error('获取角色列表失败！')
            }
            this.roleList = res.data
            this.setRoleVisible = true
        },
        // 点击确定保存新选择的角色
        async saveRoleInfo() {
            // 判断是否选择了新的角色
            if (!this.selectRoleId) {
                return this.$message.error('请选择需要分配的角色')
            }

            // 保存数据到数据库
            const { data: res } = await this.$http.put(`users/${this.usersInfo.id}/role`, { rid: this.selectRoleId })
            if (res.meta.status !== 200) {
                return this.$message.error(res.meta.msg)
            }
            this.$message.success('分配角色成功')
            // 重新查询用户列表数据
            this.getUserList()
            // 关闭对话框
            this.setRoleVisible = false
        },
        // 关闭分配权限对话框触发
        setRoleDialogClosed() {
            // 重置下拉框的内容
            this.selectRoleId = ''
            this.userInfo = {}
        }
    }
}
/* eslint-disable */
</script>
<style scoped>
.previewImg {
    width: 100%;
}

* {
    box-sizing: border-box;
}

.img-list {
    overflow: hidden;
    width: 100%;
}

.img-list .img-content {
    float: left;
    position: relative;
    display: inline-block;
    width: 200px;
    height: 270px;
    padding: 5px;
    margin: 5px 20px 20px 0;
    border: 1px solid #d1dbe5;
    border-radius: 4px;
    transition: all .3s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
}

.img-list .img-content img {
    display: block;
    width: 100%;
    height: 190px;
    margin: 0 auto;
    border-radius: 4px;
}

.img-list .img-content .name {
    margin-top: 10px;
}

.img-list .img-content .name>div {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 25px;
    line-height: 25px;
}

.img-list .img-content:hover .del,
.img-list .img-content:hover .layer {
    opacity: 1;
}

.img-list .img-content .del,
.img-list .img-content .layer {
    opacity: 0;
    transition: all .3s;
}

.img-list .img-content .del {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #8492a6;
    cursor: pointer;
    font-size: 1.1em;
}

.img-list .img-content .layer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 200px;
    color: #fff;
    text-align: center;
    z-index: 5;
    background-color: rgba(0, 0, 0, .4);
}

.img-list .img-content .layer i {
    font-size: 1.6em;
    margin-top: 80px;
}
</style>
